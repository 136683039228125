document.addEventListener('DOMContentLoaded', function(){
    eventListeners();
    backgroundColor();
    showPassword();
});

function eventListeners(){
    const mobileMenu = document.querySelector('.mobile-menu');  

    mobileMenu.addEventListener('click', navegacionResponsive);
    modalGalerias();
}

function navegacionResponsive(){
    const navegacion = document.querySelector('.header');
    const btnMenu = document.querySelector('.btnMenu');

    navegacion.classList.toggle('mostrar');
    btnMenu.classList.toggle('fa-bars');
    btnMenu.classList.toggle('fa-xmark');
}


function backgroundColor(){
    const btnNav = document.querySelectorAll('.btn-nav');
    btnNav.forEach(btn => btn.addEventListener('mouseenter', colorChange));
}

/**
 * It changes the background color of the body, the color of the buttons, the image of the panda and
 * the color of the text.
 * @param btn - the button that was hovered
 */
function colorChange(btn){
    const btnId = btn.target.id;
    const bgBody = document.querySelector('.bg-body');
    const btnNav = document.querySelectorAll('.btn-nav');
    const panda = document.querySelector('#panda');
    const dev = document.querySelector('#dev');

    switch (btnId) {
        case 'blue':
            bgBody.removeAttribute('class');
            bgBody.classList.add('bg-index');
            bgBody.classList.add('bg-body');
            bgBody.classList.add('border-azul');
            btnNav.forEach(btn => btn.removeAttribute('class'));
            btnNav.forEach(btn => btn.classList.add('btn-nav'));
            btnNav.forEach(btn => btn.classList.add('bg-azul'));
            dev.removeAttribute('class');
            dev.classList.add('txt-azul');
            panda.src='/build/img/panda-blue.webp';
            break;
        case 'pink':
            bgBody.removeAttribute('class');
            bgBody.classList.add('bg-index');
            bgBody.classList.add('bg-body');
            bgBody.classList.add('border-rosa');
            btnNav.forEach(btn => btn.removeAttribute('class'));
            btnNav.forEach(btn => btn.classList.add('btn-nav'));
            btnNav.forEach(btn => btn.classList.add('bg-rosa'));
            dev.removeAttribute('class');
            dev.classList.add('txt-rosa');
            panda.src='/build/img/panda-pink.webp';
            break;
        case 'yellow':
            bgBody.removeAttribute('class');
            bgBody.classList.add('bg-index');
            bgBody.classList.add('bg-body');
            bgBody.classList.add('border-amarillo');
            btnNav.forEach(btn => btn.removeAttribute('class'));
            btnNav.forEach(btn => btn.classList.add('btn-nav'));
            btnNav.forEach(btn => btn.classList.add('bg-amarillo'));
            dev.removeAttribute('class');
            dev.classList.add('txt-amarillo');
            panda.src='/build/img/panda-yellow.webp';
            break;
        case 'purple':
            bgBody.removeAttribute('class');
            bgBody.classList.add('bg-index');
            bgBody.classList.add('bg-body');
            bgBody.classList.add('border-morado');
            btnNav.forEach(btn => btn.removeAttribute('class'));
            btnNav.forEach(btn => btn.classList.add('btn-nav'));
            btnNav.forEach(btn => btn.classList.add('bg-morado'));        
            dev.removeAttribute('class');
            dev.classList.add('txt-morado');
            panda.src='/build/img/panda-purple.webp';
            break;
    
        default:
            bgBody.classList.add('bg-index');
            bgBody.classList.add('bg-body');
            bgBody.classList.add('border-verde');
            btnNav.forEach(btn => btn.classList.add('btn-nav'));
            btnNav.forEach(btn => btn.classList.add('bg-verde'));
            dev.classList.add('txt-verde');
            panda.src='/build/img/panda-green.webp';
            break;
    }
}

async function modalGalerias(){
    let imagen = document.querySelectorAll('.image-grid');

    imagen.forEach(image => image.addEventListener('click', abrirModal));
}

async function abrirModal(e){
    const elegida = e.target.src.split('/');
    console.log(elegida[elegida.length-1]);

    const modal = document.createElement('IMG');
    modal.src = "/images/"+elegida[elegida.length-1];

    const overlay = document.createElement('DIV');
    overlay.appendChild(modal);
    overlay.classList.add('overlay');

    const cerrarModal = document.createElement('P');
    cerrarModal.textContent='x';
    cerrarModal.classList.add('boton-cerrar');

    overlay.onclick = function(){
        overlay.remove();
        body.classList.remove('fijar-body');
    }

    cerrarModal.onclick = function(){
        overlay.remove();
        body.classList.remove('fijar-body');
    }

    overlay.appendChild(cerrarModal);

    const body = document.querySelector('body');
    body.appendChild(overlay);
    body.classList.add('fijar-body');
}



